import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Portfolios from 'components/Portfolios';
import Hero from 'components/Hero';

const PortfoliosPage: React.FC = () => {

  const { markdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "portfolio section" } }) {
          frontmatter {
            title
            subtitle
            seotitle
            seodescription
          }
        }
      }
  `);
  
  const subtitle = markdownRemark.frontmatter.subtitle;
  const title = markdownRemark.frontmatter.title;
  const seotitle = markdownRemark.frontmatter.seotitle;
  const seodescription = markdownRemark.frontmatter.seodescription;


  return (
    <Layout>
      <SEO title={seotitle} description={seodescription} />
      <Hero title={title} subtitle={subtitle}/>
      <Portfolios />
    </Layout>
  );
};

export default PortfoliosPage;