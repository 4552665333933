import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';

import Container from 'components/ui/Container';

import * as Styled from './styles';

const Portfolios = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "portfolio section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "portfolio" }, published: { eq: true } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              description
              textcolor
              date(formatString: "MMM DD, YYYY")
              cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const portfolios = allMarkdownRemark.edges.sort((a,b) => a.node.frontmatter.date - b.node.frontmatter.date);
  

  return (
    <Container section>
      <Styled.Portfolios>
        {portfolios.map((item) => {
          const {
            id,
            fields: { slug },
            frontmatter: { title, cover, description, textcolor, date }
          } = item.node;
          return (
            <Styled.PortfolioCard key={id}>
              <Link to={slug}>
                <div className="card">
                  <div className="card__side card__side--front">
                    <Img fluid={cover.childImageSharp.fluid} alt={title} />
                    <h5 style={{ backgroundColor: textcolor }}>
                      <span>
                        {title}
                      </span>
                    </h5>
                  </div>
                  <div className="card__side card__side--back" style={{ backgroundColor: textcolor}}>
                    <div>
                      <h5 className="title-span" style={{ color: 'white' }}>{title}</h5>
                      <h5 className="description-span" style={{ color: 'white' }}>{description}</h5>
                      <h5 className="date-span" style={{ color: 'white' }}>{date}</h5>
                    </div>
                  </div>
                </div>
              </Link>
            </Styled.PortfolioCard>
          );
        })}
      </Styled.Portfolios>
    </Container>
  );
};

export default Portfolios;
