import styled from 'styled-components';

export const Portfolios = styled.div`
  padding-bottom: 5rem;
  max-width: 110rem;
  margin: 0 auto;
`;

export const PortfolioCard = styled.section`
  box-sizing: border-box;
  
  @media (max-width: 800px)  {
    width: 100%;
    padding: 25px 0;
    margin: 0 auto;
  }
  @media (max-width: 450px)  {
    transform: scale(0.8);
    padding: 0;
  }
  @media (min-width: 800px)  {
    display: inline-block;
    width: 50%;
    padding: 25px;
  }

  .card {
    perspective: 150rem;
    width: 100%;
    -moz-perspective: 150rem;
    position: relative;
    height: 40rem;
    max-width: 50rem;
    margin: 0 auto;

    .gatsby-image-wrapper {
      height: 100%;
    }

    figure, figure * {
      height: 100%;
    }

    @media (max-width: 450px)  {
      picture img {
        transform: translate(0, 0) scale(1.1);
      }
    }
    @media (min-width: 450px) and (max-width: 800px)  {
      picture img {
        transform: translate(0, 0) scale(1.1);
      }
    }
    @media (min-width: 800px)  {
      picture img {
        transform: translate(0, 0) scale(1.1);
      }
    }

  
    &__side {
      height: 100%;
      transition: all 1s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      border-radius: 3px;
      overflow: hidden;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .5);
  
      &--front {
          background-color: #FFF;
      }
  
      &--back {
          transform: rotateX(-180deg);
          background-image: linear-gradient(
              160deg, 
              rgba(0, 0, 0, .9) 0%, 
              rgba(0, 0, 0, .7) 88%,
              rgba(0, 0, 0, 0) 88%
            );
          opacity: .75;

          div {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
            .title-span {
              font-family: Kosugi, sans-serif;
              font-size: 5rem;
              padding-bottom: 2rem;
              color: #FFF;
            }
            
            .description-span {
              font-family: "Source", sans-serif;
              font-size: 2.2rem;
              color: #FFF;
            }

            .date-span {
              font-family: "Source-Bold", sans-serif;
              font-size: 2.2rem;
              padding-top: 1rem;
              color: #FFF;
            }
          }
      }
    }

  }
  .card__side--front h5 {
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    font-family: "Source";
    font-size: 4rem;
    text-decoration: none;
    color: #FFF;
    display: inline-block;
    background-color: rgba(0, 0, 0, .4);
    
    span {
      padding: 0 3rem;
      box-decoration-break: clone;
      background-image: linear-gradient(
        to right bottom, 
        rgba(0, 0, 0, .32), 
        rgba(255, 255, 255, .26)
      );
    }
  }


  .card:hover .card__side--front {
    transform: rotateX(180deg);
  }

  .card:hover .card__side--back {
      transform: rotateX(0);
  }
`;